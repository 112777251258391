import { Box, Button, HStack, Icon, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { Customer, User } from "@/utils/types";
import UserRow from "./user-row";
import { formatTitle } from "@/utils/formatter";
import { HiPlus } from "react-icons/hi";
import _ from "lodash";

export default function UserList({
  users: _users,
  channelType,
  isBuyer,
  customer,
  isSettingsPage,
}: {
  users?: User[];
  channelType: "Email" | "Voicemail" | "SMS" | "GrubAssist";
  isBuyer: boolean;
  customer?: Customer;
  isSettingsPage?: boolean;
}) {
  const [users, setUsers] = useState(_users);

  useEffect(() => {
    setUsers(_users);
  }, [_users]);

  const tableScrollContainerRef = useRef<HTMLDivElement | null>(null);

  const [addRows, setAddRows] = useState<(User | undefined)[]>([]);
  return (
    <TableContainer>
      <Box overflow="scroll" maxH="360" ref={tableScrollContainerRef}>
        <Table size="sm">
          <Thead pos="sticky" top="0" zIndex="1">
            <Tr boxShadow="0 0 1px gray" bgColor="white">
              {["fullName", "title", "phoneNumber", "emailAddress", "-"].map((elem, index) => (
                <Th key={index}>{formatTitle(elem)}</Th>
              ))}
            </Tr>
          </Thead>

          <Tbody>
            {users?.map((elem) => (
              <UserRow
                user={elem}
                users={users}
                setUsers={setUsers}
                customer={customer}
                channelType={channelType}
                isUpdate={true}
                isBuyer={isBuyer}
                key={`p_${elem.userId}`}
                isSettingsPage={isSettingsPage}
              />
            ))}
            {addRows.map((elem, index) => (
              <UserRow
                user={null}
                users={users}
                setUsers={setUsers}
                customer={customer}
                isUpdate={false}
                setAddRows={setAddRows}
                channelType={channelType}
                isBuyer={isBuyer}
                key={elem?.userId || _.uniqueId()}
                currIndex={index}
                isSettingsPage={isSettingsPage}
              />
            ))}
            <Tr
              _hover={{
                bgColor: "gray.50",
                boxShadow: "0 1px 8px rgba(0, 0, 0, 0.2)",
              }}
              style={{
                position: "sticky",
                bottom: 0,
                background: "white",
              }}
            >
              <Td colSpan={6} p="0" h="10">
                <HStack justifyContent="center" h="10">
                  <Button
                    p="3"
                    w="full"
                    h="full"
                    variant="unstyled"
                    onClick={() => {
                      setAddRows((prev) => [...prev, undefined]);
                      const currentTableContainerHeight =
                        tableScrollContainerRef.current?.getBoundingClientRect()?.height;
                      if (currentTableContainerHeight && tableScrollContainerRef.current) {
                        tableScrollContainerRef.current.scrollTop = currentTableContainerHeight;
                      }
                    }}
                    aria-label="Add row"
                    cursor="pointer"
                  >
                    <Icon as={HiPlus} />
                  </Button>
                </HStack>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
    </TableContainer>
  );
}
