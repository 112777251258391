import { gql } from "graphql-request";

export const ADD_ORDER_MESSAGE = gql`
  mutation AddOrderMessage($orderId: Int!, $content: String, $type: AI_CreateOrderMessageType, $pdfUrl: String) {
    ai {
      create_ai_order_message(input: { order_id: $orderId, content: $content, type: $type, pdfUrl: $pdfUrl }) {
        id
        conversation_id
        created_ts
        creation_status
        creation_error
      }
    }
  }
`;
